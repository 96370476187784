<template>
  <nav class="home vh-100">
    <pre>
 █████  ██ ████████ ████████
██   ██ ██    ██       ██
███████ ██    ██       ██
██   ██ ██    ██       ██</pre>

    <nav>
      <span
        v-for="item in items"
        :key="item.name"
      >
        <router-link :to="item.path">
          {{ item.name }}
        </router-link>
      </span>
    </nav>
  </nav>
</template>

<script>

export default {
  components: {

  },

  data() {
    return {
      items: [
        {
          name: 'Chart Test',
          path: '/chart-test/get-figures',
        },

        {
          name: 'Diapasons',
          path: '/diapasons',
        },

        {
          name: 'Runs',
          path: '/chart-runs',
        },

        {
          name: 'Terminal',
          path: '/terminal',
        },
      ],
    }
  },
}
</script>

<style lang="stylus" scoped>
  .home
    background #010814
    color #ea2424
    display flex
    align-items center
    justify-content center
    flex-direction column
    gap 40px

    nav
      display flex
      gap 20px
      font-family var(--bs-font-monospace)

      a
        color #eb3232
        text-decoration none
        border 2px solid #ea242477
        padding 2px 6px
        border-radius 1px

        &:hover
          border-color #ea2424bb

    pre
      font-size 28px
</style>
